:root {
  --time-of-day-color-top: #ffffff;
  --time-of-day-color-bottom: #ffffff;
}

body {
  background: #012459;
  background: linear-gradient(to bottom, #012459 0%, #001322 100%);
  background: linear-gradient(
    to bottom,
    var(--time-of-day-color-top) 0%,
    var(--time-of-day-color-bottom) 100%
  );
}

html {
  font-family: Palatino, Palatino Linotype, Palatino LT STD, Book Antiqua,
    Georgia, serif;
}

#poem {
  position: relative;
  width: 24em;
  height: 7em;

  margin: 5em 5em;
}

.drop-zone {
  min-height: 150px;
}
div {
  border: 1px solid black;
  margin: 1em;
  padding: 1em;
  background-color: rgba(255, 255, 255, 0.5);
}

.type-word {
  display: inline-block;
  padding: 3px;
  margin: 2px;
}

table,
th,
td {
  border: 1px solid black;
}

h3,
h4,
h5,
h6,
p,
span {
  margin: 0;
  padding: 0;
}
